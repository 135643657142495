<template>
  <div style="height: 100%">
    <div
      class="event-detail__iframe mb-8"
      :class="{ 'event-detail__iframe_opened': openFrame }"
      v-if="openFrame"
    >
      <div class="event-detail__buttons">
        <div
          @click="closeFrame()"
          v-if="openFrame"
          class="event-detail__close-btn"
        >
          <span class="button button_pink button_small">Назад</span>
        </div>
        <div
          @click="toggleSummary(!openerSummary)"
          v-if="summary && openFrame"
          class="event-detail__summary-btn"
        >
          <span
            :class="{
              button_orange: !openerSummary,
              'button_white event-detail__summary-btn_active': openerSummary,
            }"
            class="button button_small"
            >ИИ-ассистент</span
          >
        </div>
      </div>
      <div style="height: 100%" v-if="!record">
        <iframe
          v-if="getStream && Object.keys(getStream).length && getStream[slug]"
          :id="getStream[slug].stream_id"
          :onload="`fc_load_iframe(this, 'autorun=true${streamKey}')`"
          style="border: none"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <div class="error" v-else>
          Произошла ошибка при загрузке трансляции, попробуйте позже
        </div>
      </div>
      <div v-else class="facecast-record">
        <video controls="true" :src="record" />
      </div>
      <div class="summary-modal" v-if="openerSummary">
        <div class="summary-modal__overlay" @click="toggleSummary(false)"></div>
        <div class="summary-modal__body" :class="{'summary-modal__body_no-p': showSummaryImage}">
          <div v-if="showSummaryImage">
            <img
              class="summary-modal__image"
              :src="require('@/assets/img/summary.jpg')"
            />
               <div
              class="summary-modal__close"
              v-if="!speakersScreen"
              @click="toggleSummary(false)"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.0833 15.9984L31.4208 3.68049C31.7896 3.31173 31.9967 2.81158 31.9967 2.29008C31.9967 1.76857 31.7896 1.26842 31.4208 0.89966C31.052 0.530899 30.5519 0.32373 30.0304 0.32373C29.5089 0.32373 29.0087 0.530899 28.64 0.89966L16.3221 13.2372L4.00415 0.89966C3.63539 0.530899 3.13524 0.323731 2.61373 0.323731C2.09222 0.323731 1.59208 0.530899 1.22331 0.89966C0.854553 1.26842 0.647386 1.76857 0.647386 2.29008C0.647386 2.81158 0.854553 3.31173 1.22331 3.68049L13.5608 15.9984L1.22331 28.3163C1.03976 28.4984 0.894075 28.715 0.794653 28.9536C0.695231 29.1923 0.644043 29.4482 0.644043 29.7067C0.644043 29.9653 0.695231 30.2212 0.794653 30.4599C0.894075 30.6985 1.03976 30.9151 1.22331 31.0972C1.40537 31.2807 1.62196 31.4264 1.8606 31.5258C2.09924 31.6252 2.35521 31.6764 2.61373 31.6764C2.87225 31.6764 3.12822 31.6252 3.36686 31.5258C3.6055 31.4264 3.8221 31.2807 4.00415 31.0972L16.3221 18.7597L28.64 31.0972C28.822 31.2807 29.0386 31.4264 29.2773 31.5258C29.5159 31.6252 29.7719 31.6764 30.0304 31.6764C30.2889 31.6764 30.5449 31.6252 30.7835 31.5258C31.0222 31.4264 31.2388 31.2807 31.4208 31.0972C31.6044 30.9151 31.7501 30.6985 31.8495 30.4599C31.9489 30.2212 32.0001 29.9653 32.0001 29.7067C32.0001 29.4482 31.9489 29.1923 31.8495 28.9536C31.7501 28.715 31.6044 28.4984 31.4208 28.3163L19.0833 15.9984Z"
                  fill="#fff"
                />
              </svg>
            </div>
          </div>
          <div class="summary-modal__body-inner" v-else>
            <div class="summary-modal__speakers-screen" v-if="speakersScreen">
              <div class="summary-modal__speakers-screen-title">
                Лекторы курса
              </div>
              <div
                class="summary-modal__speaker mb-4"
                v-for="speaker in summary.lectures.find(
                  (lecture) => lecture.id === speakersScreen
                ).lectors"
                :key="speaker.id"
              >
                <div class="summary-modal__speaker-avatar">
                  <img
                    :src="
                      speaker.photo
                        ? speaker.photo
                        : require(`@/assets/img/tezspire/person.svg`)
                    "
                  />
                </div>
                <div class="summary-modal__speaker-info">
                  <div class="summary-modal__speaker-name">
                    {{ speaker.first_name }}
                    {{ speaker.last_name }}
                  </div>
                  <div
                    class="summary-modal__speaker-position"
                    v-if="speaker.position"
                  >
                    {{ speaker.position }}
                  </div>
                </div>
              </div>
              <div
                class="button button_orange mt-4"
                @click="openSpeakers(null)"
              >
                Закрыть
              </div>
            </div>
            <div
              class="summary-modal__close"
              v-if="!speakersScreen"
              @click="toggleSummary(false)"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.0833 15.9984L31.4208 3.68049C31.7896 3.31173 31.9967 2.81158 31.9967 2.29008C31.9967 1.76857 31.7896 1.26842 31.4208 0.89966C31.052 0.530899 30.5519 0.32373 30.0304 0.32373C29.5089 0.32373 29.0087 0.530899 28.64 0.89966L16.3221 13.2372L4.00415 0.89966C3.63539 0.530899 3.13524 0.323731 2.61373 0.323731C2.09222 0.323731 1.59208 0.530899 1.22331 0.89966C0.854553 1.26842 0.647386 1.76857 0.647386 2.29008C0.647386 2.81158 0.854553 3.31173 1.22331 3.68049L13.5608 15.9984L1.22331 28.3163C1.03976 28.4984 0.894075 28.715 0.794653 28.9536C0.695231 29.1923 0.644043 29.4482 0.644043 29.7067C0.644043 29.9653 0.695231 30.2212 0.794653 30.4599C0.894075 30.6985 1.03976 30.9151 1.22331 31.0972C1.40537 31.2807 1.62196 31.4264 1.8606 31.5258C2.09924 31.6252 2.35521 31.6764 2.61373 31.6764C2.87225 31.6764 3.12822 31.6252 3.36686 31.5258C3.6055 31.4264 3.8221 31.2807 4.00415 31.0972L16.3221 18.7597L28.64 31.0972C28.822 31.2807 29.0386 31.4264 29.2773 31.5258C29.5159 31.6252 29.7719 31.6764 30.0304 31.6764C30.2889 31.6764 30.5449 31.6252 30.7835 31.5258C31.0222 31.4264 31.2388 31.2807 31.4208 31.0972C31.6044 30.9151 31.7501 30.6985 31.8495 30.4599C31.9489 30.2212 32.0001 29.9653 32.0001 29.7067C32.0001 29.4482 31.9489 29.1923 31.8495 28.9536C31.7501 28.715 31.6044 28.4984 31.4208 28.3163L19.0833 15.9984Z"
                  fill="#000"
                />
              </svg>
            </div>
            <transition-group name="fade" tag="div" class="summary-modal__content" v-if="!speakersScreen">
              <div
                class="summary-modal__block"
                v-for="lecture in [...summary.lectures].reverse()"
                v-show="
                  lecture.messages.length ||
                  lecture.is_current ||
                  summary.lectures.length == 1
                "
                :key="lecture.id"
              >
                <div class="summary-modal__theme">
                  <span>{{ lecture.name }}</span>
                  <div class="summary-modal__time">
                    {{ lecture.from_time.split("T")[1].split(":")[0] }}:{{
                      lecture.from_time.split("T")[1].split(":")[1]
                    }}
                    - {{ lecture.to_time.split("T")[1].split(":")[0] }}:{{
                      lecture.to_time.split("T")[1].split(":")[1]
                    }}
                  </div>
                </div>
                <div
                  class="summary-modal__speakers-container"
                  :class="{
                    'summary-modal__speakers-container_many':
                      lecture.lectors.length > 1,
                  }"
                >
                  <div
                    class="summary-modal__speaker"
                    v-for="speaker in lecture.lectors"
                    :key="speaker.id"
                  >
                    <div class="summary-modal__speaker-avatar">
                      <img
                        :src="
                          speaker.photo
                            ? speaker.photo
                            : require(`@/assets/img/tezspire/person.svg`)
                        "
                      />
                    </div>
                    <div class="summary-modal__speaker-info">
                      <div class="summary-modal__speaker-name">
                        {{ speaker.first_name }}
                        {{ speaker.last_name }}
                      </div>
                      <div
                        class="summary-modal__speaker-position"
                        v-if="speaker.position"
                      >
                        {{ speaker.position }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="summary-modal__speaker-more"
                    v-if="lecture.lectors.length > 1"
                  >
                    <div class="summary-modal__speaker-more-title">Спикеры</div>
                    <div
                      class="summary-modal__speaker-more-btn"
                      @click="openSpeakers(lecture.id)"
                    >
                      Подробнее
                    </div>
                  </div>
                </div>
                <div
                  class="summary-modal__load-anim"
                  v-if="
                    (!lecture.messages.length || lecture.is_current) &&
                    !lecture.messages.find((mes) => mes.type === 'final')
                  "
                  :class="{
                    'summary-modal__load-anim_small': lecture.messages.length,
                  }"
                >
                  <div class="summary-modal__load-anim-preloader">
                    <div class="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  <div class="summary-modal__load-anim-title">
                    Слушаю и анализирую…
                  </div>
                  <div class="summary-modal__load-anim-text">
                    В течение нескольких минут тут появятся первые тезисы по
                    докладу спикера.
                  </div>
                </div>
                <transition-group
                  class="summary-modal__messages"
                  name="fade"
                  tag="div"
                >
                  <div
                    class="summary-modal__message"
                    v-for="message in lecture.messages.filter(
                      (mes) => mes.edited_summary
                    )"
                    :class="{'summary-modal__message_final': message.type == 'final'}"
                    :key="message.id"
                    v-html="
                      message.edited_summary
                        .replaceAll(/-(.*?)(\n|$)/g, '<ul><li>$1</li></ul>')
                        .replaceAll('\n', '<br>')
                        .replaceAll(/\*{2}(.*?)\*{2}/g, '<b>$1</b>')
                    "
                  ></div>
                </transition-group>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
    <Modal
      class="stream-data"
      v-if="sendData"
      @close="toggleSendData(false)"
      :title="'Заполните данные'"
    >
      <div class="modal__item mb-4">
        <div class="modal-item__title">Email</div>
        <div class="modal-item__value">
          <TextField
            :type="'text'"
            v-model="email"
            :rules="[
              Rules.isRequired(email, 'Нужно заполнить Email'),
              Rules.isCorrectEmail(email, 'Некорректный email'),
            ]"
          ></TextField>
        </div>
      </div>
      <div class="modal__item">
        <div class="modal-item__title">Имя</div>
        <div class="modal-item__value">
          <TextField
            :type="'text'"
            v-model="name"
            :rules="[Rules.isRequired(name, 'Нужно заполнить имя')]"
          ></TextField>
        </div>
      </div>
      <div class="modal__save button button_pink" @click="sendStreamData()">
        Войти
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { bus } from "@/main";
import TextField from "@/components/form-elements/TextField.vue";
import Modal from "@/components/pageComponents/cabinet/Modal.vue";
import validate from "@/mixins/validate";
export default {
  name: "Stream",
  components: {
    TextField,
    Modal,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
    record: {
      type: String,
      default: null,
    },
    roomId: Number,
  },
  mixins: [validate],
  data: () => ({
    speakersScreen: null,
    sendData: false,
    openFrame: false,
    openerSummary: false,
    summaryTimer: null,
    email: "",
    name: "",
    summary: null,
    showSummaryImage: false
  }),
  computed: {
    ...mapGetters(["getStream", "user", "Rules", "getPassword"]),
    showStream() {
      return (
        (this.getPassword &&
          this.getPassword[this.slug] &&
          this.getStream &&
          Object.keys(this.getStream).length &&
          this.getStream[this.slug]) ||
        (this.$route &&
          this.$route.query &&
          this.$route.query.access &&
          this.user &&
          !Object.keys(this.user).length)
      );
    },
    streamKey() {
      if (this.getPassword && this.getPassword[this.slug]) {
        return `&key=${this.getPassword[this.slug]}`;
      } else return "";
    },
  },
  methods: {
    ...mapActions(["fetchStream", "fetchStreamPassword"]),
    openSpeakers(id) {
      this.speakersScreen = id;
    },
    toggleSummary(state) {
      this.openerSummary = state;
      if(state) {
        this.$emit('aiSummary')
      }
    },
    closeFrame() {
      this.openFrame = false;
      this.$emit("closeFrame");
      bus.$emit("scrollLock", false);
    },
    modalToggle(name, state) {
      this[name] = state;
      bus.$emit("scrollLock", state);
    },
    toggleSendData(state) {
      this.$emit("closeFrame");
      this.modalToggle("sendData", state);
    },
    sendStreamData() {
      bus.$emit("validate", this._uid);
      let res = bus.data.result;
      if (!res.includes(false) && res.length) {
        if (
          this.fetchStream({
            slug: this.slug,
            auth: false,
            email: this.email,
            name: this.name,
          })
        ) {
          this.toggleSendData(false);
          this.openFrame = true;
          bus.$emit("scrollLock", true);
        }
      }
    },
    openStream() {
      if (
        this.$route &&
        this.$route.query &&
        this.$route.query.access &&
        this.user &&
        !Object.keys(this.user).length &&
        (!this.getPassword || !this.getPassword[this.slug])
      ) {
        if (!this.record) {
          this.toggleSendData(true);
        } else {
          this.openFrame = true;
          bus.$emit("scrollLock", true);
        }
      } else {
        this.openFrame = true;
        bus.$emit("scrollLock", true);
      }
    },
    fetchSummary() {
      fetch(`https://gpt-parser.doclub.tech/api/room/${this.roomId}/`)
        .then((res) => res.json())
        .then((json) => {
          // if (!this.summary) {
            let result = {
              ...json.room,
              lectures: [
                ...json.room.lectures.map((lecture) => ({
                  ...lecture,
                })),
              ],
            };
            this.$set(this, "summary", result);
          // } else {
          //   for (let lectureInd in json.room.lectures) {
          //     for (let messInd in json.room.lectures[lectureInd].messages) {
          //       if (
          //         !this.summary.lectures[lectureInd].messages.find(
          //           (m) =>
          //             m.id ===
          //             json.room.lectures[lectureInd].messages[messInd].id
          //         )
          //       ) {
          //         let messages = [...this.summary.lectures[lectureInd].messages]
          //         messages.splice(0, 0, {
          //             ...json.room.lectures[lectureInd].messages[messInd]
          //         });
          //         this.$set(this.summary.lectures, lectureInd, {
          //           ...json.room.lectures[lectureInd],
          //         });
          //       }
          //     }
          //   }
          // }
          this.$set(this, 'showSummaryImage', (!(json.room.lectures.filter((lec) => lec.is_current).length) && json.room.status == "stopped"));
        });
      if (this.openFrame) {
       
        this.summaryTimerFunc();
      }
    },
    summaryTimerFunc() {
      this.summaryTimer = setTimeout(() => {
        this.fetchSummary();
      }, 10000);
    },
    addSummary(id) {
      let s = this.summary.lectures.findIndex((el) => el.id === id)
      this.summary.lectures.splice(s,1);
      // this.summary.lectures.find((el) => el.id === id).is_current = false;
    },
  },
  async mounted() {
    if (this.$route && this.$route.query && this.$route.query.email) {
      this.email = this.$route.query.email;
    }
    // && this.getStream && Object.keys(this.getStream).length && this.getStream[this.slug]
    setTimeout(() => {
      document.querySelectorAll("video").forEach((v) => {
        v.setAttribute("controlsList", "nodownload");
        v.addEventListener("contextmenu", (event) => {
          event.preventDefault();
        });
      });
    }, 1000);
  },
  watch: {
    user: {
      handler: async function (n, o) {
        if (
          this.user &&
          Object.keys(this.user).length &&
          !Object.keys(this.getStream).length &&
          !this.getStream[this.slug]
        ) {
          await this.fetchStream({ slug: this.slug, auth: true });
        }
      },
      deep: true,
      immediate: true,
    },
    open: {
      handler: async function (n, o) {
        if (this.open) {
          this.openStream();
        }
      },
      deep: true,
      immediate: true,
    },
    openFrame() {
      if (this.roomId && this.openFrame) {
        this.fetchSummary();
      } else {
        clearTimeout(this.summaryTimer);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-modal {
  position: absolute;
  top: 5vh;
  right: 1vw;
  max-height: calc(100% - 10vh);
  height: 100%;
  width: 380px;
  max-width: 90vw;
  z-index: 4;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    right: auto;
    top: 0;
    left: 0;
    max-width: none;
    max-height: none;
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  &__image {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
  }

  &__overlay {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.5;
    }
  }

  &__load-anim {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #f3f3f3;

    &_small {
      flex-direction: row;
      margin-top: 16px;
      padding-bottom: 0;
      border-bottom: 0;
      & .summary-modal {
        &__load-anim {
          &-text {
            display: none;
          }

          &-title {
            color: #6c6c6c;
          }
        }
      }

      & .lds-ellipsis {
        margin-right: 12px;
        width: 40px;
        height: 20px;
        transform: scale(0.5);
        margin-left: -6px;

        & div {
          width: 10px;
          height: 10px;
        }
      }
    }

    &-title {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: #000;
      text-align: center;
    }

    &-text {
      margin-top: 32px;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: #4c4c4c;
      text-align: center;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 36px 16px 16px 16px;
    width: 100%;
    height: auto;
    max-height: 100%;
    border-radius: 16px;
    background-color: #fff;

    &_no-p {
      padding: 0;
      overflow: hidden;
    }

    &-inner {
    width: 100%;
      height: auto;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
    }

    @media screen and (max-width: 767px) {
      position: absolute;
      z-index: 4;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 90vw;
      max-height: 90%;
    }
  }

  &__block {
     padding-right: 16px;
  }

  &__content {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
    max-height: 100%;
    width: 100%;

    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d6d6e3;
      border-radius: 5px;
      height: 8px;
    }

    &::-webkit-scrollbar--track-piece {
      border-radius: 5px;
      background-color: #f1f1f5;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #f1f1f5;
      border: 3px solid #fff;
    }

    &::-moz-scrollbar-button:decrement,
    &::-moz-scrollbar-button:increment,
    &::-moz-scrollbar-button:vertical:start:decrement,
    &::-moz-scrollbar-button:vertical:end:increment,
    &::-moz-scrollbar-button:horizontal:start:decrement,
    &::-moz-scrollbar-button:horizontal:end:increment,
    &::-webkit-scrollbar-button:vertical:start:decrement,
    &::-webkit-scrollbar-button:vertical:end:increment,
    &::-webkit-scrollbar-button:horizontal:start:decrement,
    &::-webkit-scrollbar-button:horizontal:end:increment {
      width: 0px !important;
      height: 0px !important;
    }
  }

  &__speakers {
    &-screen-title {
      margin-bottom: 16px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      color: #000;
    }
    &-container {
      border-bottom: 1px solid #f3f3f3;
      padding-bottom: 16px;
      margin-bottom: 16px;
      &_many {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        & .summary-modal__speaker {
          &:not(:first-child) {
            & .summary-modal__speaker {
              &-avatar {
                margin-left: -10px;
              }
            }
          }
          &-name {
            display: none;
          }
          &-position {
            display: none;
          }

          &-avatar {
            margin-right: 0;
          }
        }
      }
    }
  }

  &__speaker {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-more {
      margin-left: 24px;

      &-title {
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: #000;
      }

      &-btn {
        margin-top: 4px;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #bcb8ca;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &-avatar {
      width: 48px;
      height: 48px;
      border: 4px solid #fff;
      border-radius: 50%;
      margin-right: 16px;
      flex-shrink: 0;
      overflow: hidden;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    &-name {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #000;
    }

    &-position {
      margin-top: 8px;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #000;
    }
  }

  &__theme {
    margin: 0 0 16px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    color: #000;
    margin-bottom: 8px;
  }

  &__time {
    margin-top: 8px;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding: 4px 8px;
    width: fit-content;
    border-radius: 12px;
    color: #fff;
    background-color: #ff7f32;
  }

  &__message {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f3f3f3;
    background-color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #4c4c4c;

    &_final {
      border-radius: 10px;
      padding: 12px;
      background-color: #ff7f32;
      color: #fff;
    }
  }

  &__messages {
    overflow: hidden;
    position: relative;
    margin-top: 16px;
  }
}
.facecast-record {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  width: 100%;
  height: 100%;
  & video {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
  }
}
.content {
  flex-grow: 1;
}
.error {
  text-align: center;
  color: red;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.event-detail {
  &__buttons {
    // width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    position: absolute;
    column-gap: 16px;
    top: 24px;
    left: 24px;
    z-index: 2;
  }
  &__close-btn {
    cursor: pointer;
    & .button {
      height: 40px;
      padding: 0 32px;

      @media screen and (max-width: 767px) {
        height: 28px;
        padding: 0 16px;
      }
    }

    // @media screen and (max-width: 1220px) {
    //   top: 0;
    //   right: 50%;
    //   transform: translateX(50%);
    // }
  }

  &__summary-btn {
    cursor: pointer;

    &_active {
      border: 1px solid #830051;
    }

    & .button {
      height: 40px;
      padding: 0 32px;

      @media screen and (max-width: 767px) {
        height: 28px;
        padding: 0 16px;
      }
    }

    // @media screen and (max-width: 1220px) {
    //   top: 0;
    //   right: 50%;
    //   transform: translateX(50%);
    // }
  }

  &__translation {
    max-width: 280px;
    // width: 189px;

    @media screen and (max-width: 1220px) {
      width: auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__iframe {
    position: relative;
    width: 100%;
    display: none;

    & iframe {
      width: 100%;
      height: 800px;

      @media screen and (max-width: 1220px) {
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 1220px) {
      display: none;
    }
  }

  &__iframe_opened {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100%;
    height: 100%;
    background-color: #fff;
    // padding: 64px;

    & iframe {
      height: 100%;
    }

    @media screen and (max-width: 1220px) {
      // padding: 30px 0 0;
    }
  }
}

.modal {
  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__save {
    width: 117px;
    margin: 16px auto 0;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &-item {
    &__title {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #3c4242;
      width: 50px;
    }

    &__value {
      width: 280px;
      margin-left: 32px;

      @media screen and (max-width: 767px) {
        margin-left: 0;
      }
    }
  }
}

.fade-move {
  transition: all 600ms ease-in-out 100ms;
}

/* appearing */
.fade-enter-active {
  transition: all 400ms ease-out;
}

/* disappearing */
.fade-leave-active {
  transition: all 400ms ease-in;
  position: absolute;
  z-index: 0;
}

/* appear at / disappear to */
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.lds-ellipsis {
  /* change color here */
  color: #830051;
}
.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 40px;
  margin-left: 8px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33.33333%;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 0px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 0px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 24px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 48px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
<style lang="scss">
.stream-data .modal__content {
  min-width: auto;
}

.summary-modal__message {
  & b {
    font-weight: 800;
  }

  & ul {
    list-style-type: disc;
    padding-left: 6%;
  }

  & li {
    margin-bottom: 12px;
  }
}
</style>